import React from 'react';
import { withPrefix, Link } from 'gatsby';
import styled, { css } from 'react-emotion';
import { Layout } from 'components';
import Helmet from 'react-helmet';
import { FaArrowRight } from "react-icons/fa";
import config from '../../config/website';

const Project = styled.div`
  border-bottom: 3px #00F0B5 solid;
  color: #011627;
  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
      color: white;
    `};
`;

const ProjectWrapper = styled.div`
  max-width: 992px;
  width: 100%;
  padding: 2em 0;
  margin: 0 auto;
  a {
    color: gray;
  }
`;

const FullHeight = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    padding: 0 1em;
  }
`;

const Intro = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    height: 200px;
    width: 200px;
    border: 3px #00F0B5 solid;
    margin-right: 2em;
  }
  @media (max-width: 695px) {
    flex-direction: column;
    text-align: center;
    padding: 0 1em;
    img {
      margin: 0 0 2em 0;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h1 {
    margin-bottom: 0.3em;
  }
  p {
    margin: 0;
  }
  margin-bottom: 2em;
  /* justify-content: center;
  align-items: center; */
`;

const Number = styled.h1`
  font-size: 4em;
  margin-bottom: 0em;
`;

const OneCherry = styled.div`
  height: 80%;
  img {
    max-width: 100%;
  }
  @media (max-width: 1000px) {
      padding: 0 1em;
  }
`;

const Coandco = styled.div`
  img {
    max-width: 100%;
  }
  @media (max-width: 1000px) {
    padding: 0 1em;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  text-align: center;
  padding: 2em;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    color: #00F0B5;
  }
`;

const shadow = css`
  -webkit-box-shadow: 0px 0px 34px 0px rgba(212,212,212,0.75);
  -moz-box-shadow: 0px 0px 34px 0px rgba(212,212,212,0.75);
  box-shadow: 0px 0px 34px 0px rgba(212,212,212,0.75);
`;

const ocRow = css`
  @media (max-width: 850px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const ocflex = css`
  display:flex;
  padding: 2em;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
  }
  @media (max-width: 850px) {
    padding: 0;
  }
`;


const Flick = () => {
  return (
    <Layout nav={false}>
      <Helmet title={`Flick | ${config.siteTitle}`} />
      <div>
        <Project color="#011627">
          <ProjectWrapper style={{ minHeight: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Intro>
              <img src={withPrefix('/img/aidenkwok.jpg')} alt="Aiden Kwok headshot" />
              <TextContainer style={{ maxWidth: 500 }}>
                <h1> Hello Flick!</h1>
                <p>
                  I’m Aiden, a multidisciplinary designer and developer. I don’t just do one thing, I can do many things. 
                </p>
                <br/>
                <p>
                  Having worked in two early stage startups and running my own creative agency,  I enjoy turning ideas into finished products, working alongside UX designers, engineers, creatives and users. 
                  The key to my process is constant learning and having fun whilst doing so.
                </p>
                <br />
                <p>
                  Below I have selected 5 of my best work you might be interested in.
                </p>
              </TextContainer>
            </Intro>
          </ProjectWrapper>
        </Project>

        <Project>
          <ProjectWrapper>
            <OneCherry>
              <Column>
                <Row className={ocRow} style={{marginBottom: "2em"}}>
                  <img className="ocfeed" src={withPrefix('/img/ocfeed3.png')} alt="One Cherry Feed" />
                  <div className={ocflex}>
                    <Number>1</Number>
                    <h1> One Cherry</h1>
                    <p>
                      One Cherry is a social enterprise and tech startup with the aim of fighting fast fashion by allowing shops to upload used items which users can buy.
                    </p>
                    <br/>
                    <p>
                      As a designer I was responsible for creating reusable UI components and screens, and as a developer I turned them into functional lines of code.
                    </p>
                    <br/>
                    <small>Technologies include: <br/> React, Redux, React Native, and Meteor,</small>
                  </div>
                </Row>
                <img className={shadow} src={withPrefix('/img/ocscreenshots.png')} alt="One Cherry Feed" />
              </Column>
            </OneCherry>
          </ProjectWrapper>
        </Project>

        <Project>
          <FullHeight>
            <ProjectWrapper>
              <TextContainer>
                <Number>2</Number>
                <h1>The weather is (not so) random</h1>
                <p>
                  On top of commercial and function design, I like to explore the combination of art and technology. This project is a website that takes weather data from a place you search for and turns it into animations.
                </p>
                <br/>
                <small> Technologies used: <br/> React, Google Maps API, Open Weather API, and p5.js</small>
                <small><Link to="/the-weather-is-not-so-random">Find out more about this project here <FaArrowRight /></Link></small>
              </TextContainer>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ZnG5dRI0qyE?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </ProjectWrapper>
          </FullHeight>
        </Project>

        <Project>
          <ProjectWrapper>
            <Coandco>
              <Column>
                <Row>
                  <TextContainer>
                    <Number>3</Number>
                    <h1>Co & Co</h1>
                    <p>
                      This is a student-led startup and online platform with the aim of bringing together students across Edinburgh College of Art to find other students to collaborate with and opportunities from outside the university. 
                    </p>
                    <br/>
                    <p>
                      I designed and developed the UI, as well as looked after the creative direction of the brand, wrote funding applications and did anything that was needed to keep the business going.
                    </p>
                    <br/>
                    <small>
                      Technologies used: <br/>
                      Angular, Firebase, and React
                    </small>
                    <small><Link to="/co-co">Find out more about this project here <FaArrowRight /></Link></small>
                  </TextContainer>
                </Row>
                <img className={shadow} src={withPrefix('/img/coandco.png')} alt="Co and Co" />
              </Column>
            </Coandco>
          </ProjectWrapper>
        </Project>

        <Project>
          <FullHeight>
            <ProjectWrapper>
              <TextContainer>
                <Number>4</Number>
                <h1>Shape News</h1>
                <p>
                  Another example of the combination of technology and art. This mobile app is a tinder for news, allowing the user to choose only the articles they want to read.
                </p>
                <br/>
                <p>
                  It’s a social commentary on the hyperrealistic way we construct the world around us.
                </p>
                <br/>
                <small>
                  Technologies used: <br/>
                  Ionic framework and News API 
                </small>
                <small><Link to="/shape-news">Find out more about this project here <FaArrowRight /></Link></small>
              </TextContainer>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe src="https://player.vimeo.com/video/268674775" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
              </div>
            </ProjectWrapper>
          </FullHeight>
        </Project>

        <Project>
          <FullHeight>
            <ProjectWrapper>
              <TextContainer>
                <Number>5</Number>
                <h1>What inspires you?</h1>
                <p>
                  Finally, not everything I do is written with code. This is a series of illustrations and animations as a result of 20 interviews asking people about what motivates them to do what they do. (Produced as branded content inline with Microsoft Surface’s mission statement for D&AD)
                </p>
                <br/>
                <small>Programs used: <br/>
                  Adobe Illustrator and After Effects
                </small>
                <small><Link to="/what-inspires-you">Find out more about this project here <FaArrowRight /></Link></small>
              </TextContainer>
              <div style={{ border: "1px #011627 solid"}} class="embed-responsive embed-responsive-16by9">
                <iframe src="https://player.vimeo.com/video/268774100" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
              </div>
            </ProjectWrapper>
          </FullHeight>
        </Project>

        <Project>
          <FullHeight>
            <ProjectWrapper>
              <Footer>
                <h3>Thanks for taking the time to look at my portfolio!</h3>
                <br />
                <p>If you want to check out more of my work <br/> you can see all my projects <Link to="/work">here!</Link></p>
                <br/>
                <p><a href="mailto:hello@aidenkwok.co.uk?&amp;subject=Hello! It's Flick">hello@aidenkwok.co.uk</a></p>
                <p>+44 7843155422</p>
              </Footer>
            </ProjectWrapper>
          </FullHeight>
        </Project>
      </div>
    </Layout>
  );
};

export default Flick;